<template>
  <div data-smart-tv-control>
    <video-control-button
      :text="text"
      aria-label="''"
      aria-keyshortcuts="Space"
      :on-click="requestDebugMenu"
      :data-navigatable="getNavigationAttribute(SmartTvPlayerNavigatableGroupName.PlayerControls)"
    >
      <span>{{ text }}</span>
    </video-control-button>
  </div>
</template>

<script lang="ts" setup>
import VideoControlButton from '@PLAYER/player/components/controls/VideoControlButton.vue';
import useDebugActions from '@PLAYER/player/modules/debug/use-debug-actions';
import {
  SmartTvPlayerNavigatableGroupName,
  useNavigatableMap,
} from '@PLAYER/player/versions/smart/modules/smart-navigation/use-navigatable-map';

const { getNavigationAttribute } = useNavigatableMap();
const { requestDebugMenu } = useDebugActions();

const text = 'Отладка';
</script>
