import { isUndefinedOrNull } from '@package/sdk/src/core';
import { isClient } from '@vueuse/core';

export const isServer = !isClient;

const REAL_DEVICE_USER_AGENT = isClient ? window.navigator.userAgent : '';

enum UserAgent {
  WebOS,
  TizenOS,
  iOS,
  Android,
  Mobile,
}

export const isDevtoolMode = Boolean(process.env.IS_DEVTOOL);

const userAgentMap = {
  [UserAgent.Mobile]:
    'Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Mobile Safari/537.36',
  [UserAgent.iOS]:
    'Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1',
  [UserAgent.Android]:
    'Mozilla/5.0 (Linux; Android 11; Pixel 5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/90.0.4430.91 Mobile Safari/537.36',
  [UserAgent.WebOS]:
    'Mozilla/5.0 (Web0S; Linux/SmartTV) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.128 Safari/537.36 HbbTV/1.6.1 (+DRM; LGE; OLED77C34LA; WEBOS23 03.11.10; W23_O22N; DTV_W23O;)',
  [UserAgent.TizenOS]:
    'Mozilla/5.0 (SMART-TV; LINUX; Tizen 3.0) AppleWebKit/538.1 (KHTML, like Gecko) Version/3.0 TV Safari/538.1',
};

const getUserAgent = () => {
  return REAL_DEVICE_USER_AGENT;
};

const INSTALLED_USER_AGENT = getUserAgent();

export const isTouchDevice = isClient ? Reflect.has(window, 'ontouchstart') : false;

export const isChromecastProvided = isClient ? window.chrome && !window.chrome.cast : false;

export const isDev = process.env.NODE_ENV === 'development';

const webOSDeviceInfo = isClient && window.PalmSystem ? JSON.parse(window.PalmSystem.deviceInfo) : {};

export const isWebOSSimulator = isClient ? webOSDeviceInfo.modelName?.toLowerCase().includes('simulator') : false;

export const isWebOS = isClient ? Reflect.has(window, 'PalmSystem') : false;

export const isTizenOS = isClient ? /Tizen/.test(INSTALLED_USER_AGENT) : false;

const isMac = isClient ? /Macintosh/i.test(INSTALLED_USER_AGENT) : false;
export const isIpad = isClient ? isMac && isTouchDevice : false;

export const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(INSTALLED_USER_AGENT) || isIpad;

export const isIOS = /iPad|iPhone|iPod/.test(INSTALLED_USER_AGENT);
export const isAndroid = /(android)/i.test(INSTALLED_USER_AGENT);

export const isFirefox = /Firefox|FxiOS/i.test(INSTALLED_USER_AGENT);
export const isChromium = isClient ? 'chrome' in window : false;
export const isDesktop = !isMobile;

export const isFullscreeniOSApiEnabled = isClient ? isIOS && document.fullscreenEnabled : false;

const getIOSVersion = () => {
  if (isIOS) {
    const v = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);

    if (isUndefinedOrNull(v)) {
      return 0;
    }

    const major = parseInt(v[1], 10);
    const minor = parseInt(v[2], 10);

    return parseFloat(String(major) + '.' + String(minor));
  }

  return 0;
};

export const iOSVersion = isClient ? getIOSVersion() : 0;

if (isIOS) {
  console.info('%c INFO', 'color: #33f', 'Detected iOS version - ' + iOSVersion);
}

export const isSafari = Boolean(
  isClient
    ? window.navigator.vendor &&
        window.navigator.vendor.includes('Apple') &&
        INSTALLED_USER_AGENT &&
        !INSTALLED_USER_AGENT.includes('CriOS') &&
        !INSTALLED_USER_AGENT.includes('FxiOS')
    : false,
);

export const CSSSupportMap: Record<string, boolean> = {
  HasSelector: isClient ? typeof CSS !== 'undefined' && CSS.supports('selector(:has(*))') : false,
};

export function ensurePromiseExist(promise?: Promise<void>): Promise<void> | undefined {
  if (promise) {
    return promise;
  }

  return undefined;
}

export function getActiveDocumentElement(): HTMLElement | undefined {
  if (document.activeElement) {
    return document.activeElement as HTMLElement;
  }

  return undefined;
}

export function findRight<T>(array: T[], predicate: (value: T) => boolean): T | undefined {
  let index = array.length;

  while (index > 0) {
    index = index - 1;

    const item = array[index];

    const predicateResult = Reflect.apply(predicate, undefined, [item]);

    if (predicateResult) {
      return item;
    }
  }

  return undefined;
}

export function getPlatformName(): string {
  if (isWebOSSimulator) {
    return 'WebOS Simulator';
  }

  if (isTizenOS) {
    return 'TizenOS';
  }

  if (isWebOS) {
    return 'WebOS';
  }

  return 'Web';
}

interface LoadScriptElementOptions {
  attrs?: Record<string, string | boolean | number>;
  async?: boolean;
  defer?: boolean;
  onLoad?: VoidFunction;
}

export const isNavigatorMediaSessionAvailable = isClient ? Reflect.has(window.navigator, 'mediaSession') : false;

export function loadScriptElement(src: string, options: LoadScriptElementOptions = {}) {
  if (!isClient) {
    return;
  }

  const script = document.createElement('script');
  const { attrs = {}, onLoad = () => ({}), defer = false, async = false } = options;

  script.onload = onLoad;
  script.async = async;
  script.defer = defer;

  script.src = src;

  const attrsEntries = Object.entries(attrs);

  for (const [key, value] of attrsEntries) {
    script.setAttribute(key, String(value));
  }

  document.head.appendChild(script);
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it('Default platform name', () => {
    const name = getPlatformName();

    expect(name).toBe('Web');
  });
}
