import { ApiError } from '../api-error';
import { Endpoints } from '../endpoints';
import type { RequestService } from '../request-service';
import { HTTPRequestMethod } from '../request-service';

export class ParentalService {
  constructor(private readonly $http: RequestService) {}

  public abort(message = 'Cancelled by user'): void {
    this.$http.abort(message);
  }

  public async createControl(code: string | number, ageLimit: string | number): Promise<boolean> {
    try {
      await this.$http.request(
        {
          method: HTTPRequestMethod.Post,
          url: Endpoints.ParentalControl,
          params: {
            code,
            age_limit: ageLimit,
          },
        },
        { withToken: true, transformResult: true },
      );
      return true;
    } catch (e) {
      throw new ApiError(e as Error);
    }
  }

  public updateControl(code: string | number, ageLimit: string | number): Promise<unknown> {
    return this.$http.request(
      {
        method: HTTPRequestMethod.Put,
        url: Endpoints.ParentalControl,
        params: {
          code,
          age_limit: ageLimit,
        },
      },
      { withToken: true, transformResult: true },
    );
  }

  public setChildrenAccess(code?: string | number): Promise<unknown> {
    return this.$http.request(
      {
        method: HTTPRequestMethod.Post,
        url: Endpoints.ParentalControlSetAccess,
        params: {
          code,
        },
      },
      { withToken: true, transformResult: true },
    );
  }

  public updateChildrenAccess(code: string | number): Promise<unknown> {
    return this.$http.request(
      {
        method: HTTPRequestMethod.Put,
        url: Endpoints.ParentalControl,
        params: {
          code,
          age_limit: 18,
        },
      },
      { withToken: true, transformResult: true },
    );
  }

  public resetChildrenAccess(): Promise<unknown> {
    return this.$http.request(
      {
        method: HTTPRequestMethod.Post,
        url: Endpoints.ParentalControlResetAccess,
      },
      { withToken: true, transformResult: true },
    );
  }

  public deleteControl(): Promise<unknown> {
    return this.$http.request(
      {
        method: HTTPRequestMethod.Delete,
        url: Endpoints.ParentalControl,
      },
      { withToken: true, transformResult: true },
    );
  }

  public async checkControl(code: string | number): Promise<boolean> {
    try {
      await this.$http.request(
        {
          method: HTTPRequestMethod.Get,
          url: Endpoints.ParentalCheckControl,
          params: {
            code,
          },
        },
        { withToken: true, transformResult: true },
      );

      return true;
    } catch (error) {
      throw new ApiError(error as Error);
    }
  }
}
