<template>
  <timeline-frame-preview-image
    :src="previewSrc"
    :on-click="onRequestEpisode"
    :data-navigatable="getNavigationAttribute(SmartTvPlayerNavigatableGroupName.PlayerTimelinePlayback)"
    :class="{
      [$style.wrapper]: true,
      [$style.wrapperAnimated]: isButtonAnimated,
    }"
  >
    <template #text>
      <p :class="$style.description">
        <span :class="$style.text">{{ $t('vod.nextMedia') }}</span>
        <span :class="$style.time">{{ episodeTitle }}</span>
      </p>
    </template>
  </timeline-frame-preview-image>
</template>

<script setup lang="ts">
import { AnyFunction } from '@PLAYER/player/base/function';
import TimelineFramePreviewImage from '@PLAYER/player/components/timeline/TimelineFramePreviewImage.vue';
import {
  SmartTvPlayerNavigatableGroupName,
  useNavigatableMap,
} from '@PLAYER/player/versions/smart/modules/smart-navigation/use-navigatable-map';

defineProps<{
  previewSrc: string;
  onRequestEpisode: AnyFunction;
  episodeTitle: string;
  isButtonAnimated: boolean;
}>();

const { getNavigationAttribute } = useNavigatableMap();
</script>

<style module lang="scss">
@use '@package/ui/src/styles/fonts.scss' as webFonts;
@use '@package/ui/src/styles/smarttv-fonts.scss' as smartTvFonts;

.wrapper {
  overflow: hidden;
}

.wrapperAnimated {
  --next-episode-animation-duration: 6s;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: var(--z-index-next-episode-button-pseudo);
    width: 0;
    background-color: var(--color-notheme-bg-tertiary-80);
    animation: var(--next-episode-animation-duration) linear button-filling;
  }
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--g-spacing-4) var(--g-spacing-8) var(--g-spacing-6);
}

.text {
  color: var(--color-notheme-text-primary);
  @include webFonts.WebLabel-3();
}

.time {
  color: var(--color-notheme-text-secondary);
  margin-top: var(--g-spacing-2);
  text-transform: uppercase;
  @include webFonts.WebBody-3();
}

@keyframes button-filling {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
