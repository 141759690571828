const RECONCILATION_POINT_ID = 'coordinates_reconciliation_point';

export function getCoordinates(element: HTMLElement): DOMRect | undefined {
  const rect = element.getBoundingClientRect();
  const reconcilationPoint = document.getElementById(RECONCILATION_POINT_ID);

  if (!reconcilationPoint || !reconcilationPoint.offsetLeft || !reconcilationPoint.offsetTop) {
    return rect;
  }

  const scaleX = window.innerWidth / reconcilationPoint.offsetLeft;
  const scaleY = window.innerHeight / reconcilationPoint.offsetTop;

  if ('DOMRect' in window) {
    const newRect = new DOMRect(rect.x * scaleX, rect.y * scaleY, rect.width, rect.height);

    return newRect;
  }

  return undefined;
}

export function createReconcilationPoint() {
  const reconcilationPoint = document.createElement('div');
  document.querySelector('body')?.appendChild(reconcilationPoint);

  reconcilationPoint.id = RECONCILATION_POINT_ID;
  reconcilationPoint.style.position = 'fixed';
  reconcilationPoint.style.top = '100%';
  reconcilationPoint.style.left = '100%';
}
