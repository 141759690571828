import type { ConsolaOptions } from 'consola';
import { createConsola } from 'consola';

const options: Partial<ConsolaOptions> = {};

const logger = createConsola(options);

type ProjectName = 'dsml-js' | 'media-player' | 'web' | 'smarttv' | 'media-player-analyzer';

export default function useLogger(scope?: string, projectName?: ProjectName) {
  if (scope && projectName) {
    return logger.withTag(`${projectName}:${scope}`);
  }

  if (!scope && projectName) {
    return logger.withTag(projectName);
  }

  if (scope) {
    return logger.withTag(scope);
  }

  return logger;
}
