<template>
  <root-video-wrapper :projector="projector">
    <video-player
      :projector="projector"
      :src="src"
      :loop="loop"
      :poster-src="poster"
      :preload="preload"
      :muted="muted"
      :autoplay="autoplay"
    >
      <projector-video-combined
        :projector="projector"
        :src="src"
        :loop="loop"
        :poster-src="poster"
        :preload="preload"
        :muted="muted"
        :autoplay="autoplay"
      />
    </video-player>
  </root-video-wrapper>
</template>

<script lang="ts" setup>
import RootVideoWrapper from '@PLAYER/player/components/player/RootVideoWrapper.vue';
import ProjectorVideoCombined from '@PLAYER/player/components/projectors/ProjectorVideoCombined.vue';
import useConfigStore from '@PLAYER/player/modules/store/config-store';
import VideoPlayer from '@PLAYER/player/versions/smart/components/player/VideoPlayer.vue';
import { isClient } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { provide } from 'vue';

provide('platform.smart', true);

if (isClient && window.$vijuPlayer) {
  window.$vijuPlayer.isSmartTV = true;

  const nullSetOnPressCallback = () => {
    //
  };

  const nullSetActiveNavigationItem = (element: HTMLElement) => {
    element.focus();
  };

  window.$setOnPressBackCallback = window.$setOnPressBackCallback || nullSetOnPressCallback;
  window.$setActiveNavigationItem = window.$setActiveNavigationItem || nullSetActiveNavigationItem;
}

const { src, projector, poster, preload, autoplay, muted, loop } = storeToRefs(useConfigStore());
</script>
