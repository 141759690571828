import useLogger from '@package/logger/src/use-logger';
import AppEvent from '@package/sdk/src/core/event/event';
import { EventEmitter } from '@package/sdk/src/core/event/event-emitter';
import { Disposable, IDisposable } from '@package/sdk/src/core/lifecycle/disposable';

import type { IStorageService } from '../storage/storage-service';
import { StorageKeys } from '../storage/storage-types';

export interface TelemetryEvent {
  event: object | object[];
}

interface TelemetryEventMap {
  [key: string]: TelemetryEvent;
}

const MAX_BUFFER_SIZE = 200;
const TELEMETRY_BUFFER_STORE_TIMEOUT_MS = 10_000;

const logger = useLogger('telemetry-service');

export class TelemetryService extends Disposable {
  private readonly emitter: EventEmitter<TelemetryEventMap> = new EventEmitter();

  private readonly buffer: TelemetryEvent[] = [];

  constructor(private readonly $storage: IStorageService) {
    super();
    this.buffer = $storage.getItem<TelemetryEvent[]>(StorageKeys.Telemetry, []);

    window.setInterval(() => {
      this.$storage.setItem(StorageKeys.Telemetry, this.buffer);
    }, TELEMETRY_BUFFER_STORE_TIMEOUT_MS);
  }

  public on(event: string, listener: (arg: TelemetryEvent) => void): IDisposable {
    return this.emitter.on(event, listener);
  }

  public trackUserEvent(event: AppEvent): void {
    // Не выводим это в консоль
    if (event.type === 'keyboard' || event.type === 'wheel' || event.type === 'mouse') {
      return;
    }

    this.storeEvent(event);
  }

  public get events(): TelemetryEvent[] {
    return this.buffer;
  }

  private storeEvent(event: object | object[]): void {
    if (this.buffer.length >= MAX_BUFFER_SIZE) {
      this.buffer.pop();
    }

    const telemetryEvent = { event };

    this.buffer.unshift(telemetryEvent);

    logger.info('TelemetryEvent', event);
  }
}
