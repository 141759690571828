import { useAuthPageAnalytics } from '@package/sdk/src/analytics';

import { AuthState, useAuthStore } from '../../../stores/use-auth-store';
import { SessionGetters, SessionState, useSessionStore } from '../../../stores/use-session-store';
import { storeToRefs } from '../../../stores/utils/store-to-refs';
import { analyticService, globalSettings, routerService } from '../../index';
import { RouterPage } from '../../router/router';

type RouteLocationRaw = any;

interface IRedirectUserOptions {
  replace: boolean;
}

export enum AuthSteps {
  SignInUp = 'signInUp',
  SignInPassword = 'password',
  SignUpPassword = 'newPassword',
  RecoverPassword = 'restorePassword',

  RecoverCode = 'passwordCode',
}

export function useAuthActions() {
  const authStore = useAuthStore();
  const sessionStore = useSessionStore();
  const authPageAnalytics = useAuthPageAnalytics(analyticService.sender);

  const { isPartnerUser, isActiveSubscription, isChildProfileSet, hasChildProfile } = storeToRefs<
    SessionState,
    SessionGetters,
    unknown
  >(sessionStore);
  const { backUrl, selectedOfferId, loginInput, otpCode } = storeToRefs<AuthState, unknown, unknown>(authStore);

  // Пока так
  const isBackUrlToOffers = globalSettings.computed(() => backUrl.value.includes('offer'));

  const redirectUserWithBackUrl = (route: RouteLocationRaw, options?: IRedirectUserOptions) => {
    if (options?.replace) {
      return routerService.replace(route);
    }

    return routerService.push(route);
  };

  const openAuthPage = () => {
    if (!backUrl.value) {
      authStore.setBackUrl(routerService.fullPath);
    }

    authPageAnalytics.onGotoAuthPage(analyticService.getAnalyticPageName());

    return routerService.push({ name: RouterPage.AuthPage });
  };

  const getBackRedirectUrl = (): RouteLocationRaw => {
    if (!backUrl.value) {
      return { name: isChildProfileSet.value ? RouterPage.ProfilesPage : RouterPage.MainPage };
    }

    return backUrl.value;
  };

  const getRedirectRouteAfterChoosingProfile = () => {
    if (backUrl.value) {
      return getBackRedirectUrl();
    }

    return { name: RouterPage.MainPage };
  };

  const getRedirectRouterAfterSuccessfulSubscription = () => {
    if (!isChildProfileSet.value) {
      return { name: RouterPage.ParentalPage };
    }

    return { name: RouterPage.ProfilesPage };
  };

  const getRedirectRouterAfterAuthorization = (): RouteLocationRaw => {
    if (isActiveSubscription.value) {
      return { name: hasChildProfile.value ? RouterPage.ProfilesPage : RouterPage.MainPage };
    }

    if (!isPartnerUser.value) {
      if (selectedOfferId.value) {
        return { name: RouterPage.OfferInfo, params: { offerId: selectedOfferId.value } };
      }

      return getBackRedirectUrl();
    }

    return isBackUrlToOffers.value
      ? { name: RouterPage.MainPage, query: routerService._router.query }
      : getBackRedirectUrl();
  };

  const redirect = async () => {
    await sessionStore.fetchUser();

    const route = getRedirectRouterAfterAuthorization();

    authStore.clearAuthArtifacts();
    return redirectUserWithBackUrl(route);
  };

  return {
    openAuthPage,
    redirectUserWithBackUrl,
    getRedirectRouterAfterAuthorization,
    getRedirectRouteAfterChoosingProfile,
    getRedirectRouterAfterSuccessfulSubscription,
    redirect,
    loginInput,
    otpCode,
  };
}
