import useLogger from '@package/logger/src/use-logger';
import { Moment } from '@package/sdk/src/api/content/types/moment';
import { MomentMapper } from '@package/sdk/src/api/moments/moment';
import { ApiMoment } from '@package/sdk/src/api/moments/moment-types/moment';
import { Playlist } from '@package/sdk/src/api/playlists/playlists';
import { LikeState } from '@package/sdk/src/api/user-collection/like-state';

import {
  MOMENT_CANCELLED_STORAGE_KEY,
  MOMENT_DISLIKED_STORAGE_KEY,
  MOMENT_LIKED_STORAGE_KEY,
  MOMENTS_REMOVED_STORAGE_KEY,
  MOMENTS_SAVED_STORAGE_KEY,
  NUMBER_OF_ITEMS_TO_SAVE_LIMIT,
} from '../collection/common';
import { Endpoints } from '../endpoints';
import type { RequestService } from '../request-service';
import { HTTPRequestMethod } from '../request-service';
import type { IStorageService } from '../storage/storage-service';
import type { FetchMomentsOptions } from './moment-types';

const logger = useLogger('moment-service');

export class MomentService {
  constructor(
    private readonly $http: RequestService,
    private readonly $storage: IStorageService,
  ) {}

  get likedItems() {
    return (this.$storage.getItem<string[]>(MOMENT_LIKED_STORAGE_KEY) || []) as string[];
  }

  set likedItems(data: string[]) {
    this.$storage.setItem(MOMENT_LIKED_STORAGE_KEY, data);
  }

  get dislikedItems() {
    return (this.$storage.getItem<string[]>(MOMENT_DISLIKED_STORAGE_KEY) || []) as string[];
  }

  set dislikedItems(data: string[]) {
    this.$storage.setItem(MOMENT_DISLIKED_STORAGE_KEY, data);
  }

  get cancelledItems() {
    return (this.$storage.getItem<string[]>(MOMENT_CANCELLED_STORAGE_KEY) || []) as string[];
  }

  set cancelledItems(data: string[]) {
    this.$storage.setItem(MOMENT_CANCELLED_STORAGE_KEY, data);
  }

  get savedMomentsItems() {
    return (this.$storage.getItem(MOMENTS_SAVED_STORAGE_KEY) || []) as string[];
  }

  set savedMomentsItems(ids: string[]) {
    this.$storage.setItem(MOMENTS_SAVED_STORAGE_KEY, ids);
  }

  get removedMomentsItems() {
    return (this.$storage.getItem(MOMENTS_REMOVED_STORAGE_KEY) || []) as string[];
  }

  set removedMomentsItems(ids: string[]) {
    this.$storage.setItem(MOMENTS_REMOVED_STORAGE_KEY, ids);
  }

  public abort(message = 'Cancelled by user'): void {
    this.$http.abort(message);
  }

  public async fetchPlaylists(): Promise<Playlist[]> {
    const { data } = await this.$http.request<{ playlists: Playlist[] }>(
      {
        method: HTTPRequestMethod.Get,
        url: Endpoints.Playlists,
      },
      { transformResult: true },
    );

    return data?.playlists || [];
  }

  public async fetchMoment(id: string): Promise<Moment> {
    const { data } = await this.$http.request<ApiMoment>(
      {
        method: HTTPRequestMethod.Get,
        url: Endpoints.MomentId.replace(':id', id),
      },
      { transformResult: true },
    );

    return MomentMapper.map(data);
  }

  public async fetchMoments({ page = 1, perPage = 5, code }: FetchMomentsOptions): Promise<Moment[]> {
    const { data } = await this.$http.request<ApiMoment[]>(
      {
        method: HTTPRequestMethod.Get,
        url: Endpoints.MomentsFeed,
        params: {
          page,
          per_page: perPage,
          ...(code && { playlist_code: code }),
        },
      },
      { withToken: true, transformResult: true, skipTokenValidation: true },
    );

    const saved = {
      likedItems: this.likedItems,
      dislikedItems: this.dislikedItems,
      savedMomentsItems: this.savedMomentsItems,
      removedMomentsItems: this.removedMomentsItems,
    };

    const moments = MomentMapper.mapMany(data);

    return moments.map((moment) => {
      let likeState = moment.likeState;

      if (saved.likedItems?.find((id) => id === moment.id)) {
        likeState = LikeState.Like;
      }

      if (saved.dislikedItems?.find((id) => id === moment.id)) {
        likeState = LikeState.Dislike;
      }

      let saveState = moment.inUserCollection;

      if (saved.savedMomentsItems?.find((id) => id === moment.id)) {
        saveState = true;
      }

      if (saved.removedMomentsItems?.find((id) => id === moment.id)) {
        saveState = false;
      }

      return { ...moment, likeState, inUserCollection: saveState };
    });
  }

  public async saveItems() {
    try {
      if (this.likedItems.length || this.dislikedItems.length || this.cancelledItems.length) {
        await this.$http.request(
          {
            method: HTTPRequestMethod.Post,
            url: Endpoints.MomentsReactions,
            data: {
              like: this.likedItems,
              dislike: this.dislikedItems,
              cancel: this.cancelledItems,
            },
          },
          { withToken: true, transformResult: true },
        );

        this.likedItems = [];
        this.dislikedItems = [];
        this.cancelledItems = [];
      }
    } catch (e) {
      logger.error(e as any);
    }
  }

  public async likeMoment(ids: string[], reaction: LikeState): Promise<void> {
    try {
      await this.$http.request(
        {
          method: HTTPRequestMethod.Post,
          url: Endpoints.MomentsReactions,
          data: {
            ...(reaction === LikeState.Like && { like: ids }),
            ...(reaction === LikeState.Dislike && { dislike: ids }),
            ...(reaction === LikeState.Cancel && { cancel: ids }),
          },
        },
        { withToken: true, transformResult: true },
      );
    } catch (e) {
      let likedItems: string[] = this.likedItems || [];
      let dislikedItems: string[] = this.dislikedItems || [];
      let cancelledItems: string[] = this.cancelledItems || [];

      if (reaction === LikeState.Like) {
        likedItems = Array.from(new Set([...this.likedItems, ...ids]));
        const dislikedIndex = dislikedItems.findIndex((id) => ids.includes(id));

        if (dislikedIndex >= 0) {
          dislikedItems.splice(dislikedIndex, 1);
        }

        const cancelledIndex = cancelledItems.findIndex((id) => ids.includes(id));

        if (cancelledIndex >= 0) {
          cancelledItems.splice(cancelledIndex, 1);
        }
      }

      if (reaction === LikeState.Dislike) {
        dislikedItems = Array.from(new Set([...this.dislikedItems, ...ids]));

        const likedIndex = likedItems.findIndex((id) => ids.includes(id));

        if (likedIndex >= 0) {
          likedItems.splice(likedIndex, 1);
        }

        const cancelledIndex = cancelledItems.findIndex((id) => ids.includes(id));

        if (cancelledIndex >= 0) {
          cancelledItems.splice(cancelledIndex, 1);
        }
      }

      if (reaction === LikeState.Cancel) {
        cancelledItems = Array.from(new Set([...this.cancelledItems, ...ids]));

        const likedIndex = likedItems.findIndex((id) => ids.includes(id));

        if (likedIndex >= 0) {
          likedItems.splice(likedIndex, 1);
        }

        const dislikedIndex = dislikedItems.findIndex((id) => ids.includes(id));

        if (dislikedIndex >= 0) {
          dislikedItems.splice(dislikedIndex, 1);
        }
      }

      if (likedItems.length + dislikedItems.length > NUMBER_OF_ITEMS_TO_SAVE_LIMIT) {
        throw new Error('pages.moments.limitReached.title');
      }

      this.likedItems = likedItems;
      this.dislikedItems = dislikedItems;
      this.cancelledItems = cancelledItems;
    }
  }
}
