import useLogger from '@package/logger/src/use-logger';

import type { EnvironmentService } from '../environment/environment-service';
import { globalSettings } from '../global-settings';
import type { IStorageService } from '../storage/storage-service';
import { StorageKeys } from '../storage/storage-types';
import type { AbstractManufacturerService } from './abstract-manufacturer-service';
import { DesktopService } from './desktop-service';
import { Device, OperationSystem, SetVolumeLevel } from './device-types';
import { TizenService } from './tizen-service';
import { VidaaService } from './vidaa-service';
import { WebOsService } from './webos-service';

declare global {
  interface Window {
    platform?: string;
  }

  interface Navigator {
    userAgentData?: {
      platform?: string;
    };
  }
}

const logger = useLogger('device-service');

const platform = window.navigator.platform ?? window.navigator?.userAgentData?.platform;

const getCurrentOperationSystem = (): OperationSystem => {
  const userAgent = window.navigator.userAgent;

  let os = OperationSystem.Desktop;

  if (/Mac/.test(platform)) {
    os = OperationSystem.Desktop;
  } else if (/Android/.test(userAgent)) {
    os = OperationSystem.Mobile;
  } else if (/WebAppManager/.test(userAgent)) {
    os = OperationSystem.WebOs;
  } else if (/WebOs/.test(userAgent)) {
    os = OperationSystem.WebOs;
  } else if (/Web0S/.test(userAgent)) {
    os = OperationSystem.WebOs;
  } else if (/Tizen/.test(userAgent)) {
    os = OperationSystem.Tizen;
  } else if (/Win/.test(platform)) {
    os = OperationSystem.Desktop;
  } else if (/Linux/.test(platform)) {
    os = OperationSystem.Desktop;
  } else if (/iP/.test(platform)) {
    os = OperationSystem.Mobile;
  } else if (/AndroidTV/.test(platform)) {
    os = OperationSystem.AndroidTV;
  } else if (/VIDAA/.test(platform)) {
    os = OperationSystem.Vidaa;
  } else if (/Hisense/.test(platform)) {
    os = OperationSystem.Vidaa;
  }

  return os;
};

export class DeviceService {
  private _os: OperationSystem = getCurrentOperationSystem();
  private readonly manufacturerService: AbstractManufacturerService;

  constructor(
    private readonly $storage: IStorageService,
    private readonly $environment: EnvironmentService,
  ) {
    switch (this._os) {
      case OperationSystem.Tizen:
        this.manufacturerService = new TizenService();
        break;
      case OperationSystem.WebOs:
        this.manufacturerService = new WebOsService();
        break;
      case OperationSystem.Vidaa:
        this.manufacturerService = new VidaaService();
        break;
      default:
        this.manufacturerService = new DesktopService(this);
    }
  }

  public async getFullDeviceInfo() {
    try {
      const data = await this.manufacturerService.getDeviceInfo();

      return data;
    } catch (error) {
      logger.error(String(error));

      return undefined;
    }
  }

  public getOSInformation() {
    return getCurrentOperationSystem();
  }

  public init() {
    const tokens = this.getAvailableFeatures().map((feat) => `has-${feat}`);
    document.documentElement.classList.add(...tokens);

    return this.manufacturerService.init();
  }

  public reloadApp() {
    return window.location.reload();
  }

  public getDevice(): Device {
    const manufacturerName = this.manufacturerService.getManufacturerName();
    return {
      uid: this.deviceUUID,
      app_version: this.$environment.getVariable<() => string>('appVersion')(),
      os: this.os,
      name: manufacturerName,
      type: 'tv',
      manufacturer: this.manufacturerService.getManufacturerName(),
    };
  }

  public isWeakDevice(): boolean {
    return this.manufacturerService.isWeakDevice();
  }

  public setVolume(volume: SetVolumeLevel): void {
    return this.manufacturerService.setVolume(volume);
  }

  public getVolume(): number {
    return this.manufacturerService.getVolume();
  }

  public getAvailableFeatures() {
    return this.manufacturerService.getAvailableFeatures();
  }

  public get deviceUUID() {
    let deviceUUID = this.$storage.getItem(StorageKeys.DeviceUUID);

    if (!deviceUUID) {
      deviceUUID = globalSettings.uuidv4();
      this.$storage.setItem(StorageKeys.DeviceUUID, deviceUUID);
    }

    return deviceUUID as string;
  }

  public getVisitorId(): string {
    let visitorId = this.$storage.getItem(StorageKeys.Visitor);

    if (!visitorId) {
      visitorId = globalSettings.uuidv4();
      this.$storage.setItem(StorageKeys.Visitor, visitorId);
    }

    return visitorId as string;
  }

  public get os() {
    return this._os;
  }

  public exit() {
    return this.manufacturerService.exit();
  }
}
