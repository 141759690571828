// import { marked } from 'marked';
// import sanitizeHtml from 'sanitize-html';

export default function useMarked() {
  // const renderer = new marked.Renderer();
  // const doubleEqualAround = /==([^=]+)==/g;
  //
  // // Удаляем обёртку <p></p> и добавляем новое правило
  // renderer.paragraph = (text: string) => text;
  // renderer.text = (text: string) => text.replace(doubleEqualAround, '<span class="highlight">$1</span>');
  //
  // // Изменяем существующее правило
  // renderer.del = (text: string) => `<span class="striked">${text}</span>`;
  //
  // // Разрешаем класс в sanitizeHtml
  // const rules = {
  //   allowedAttributes: {
  //     span: ['class'],
  //     a: ['href'],
  //   },
  // };

  const renderMarkdown = (_: string) => {
    return '';
  };

  return {
    renderMarkdown,
  };
}
