<template>
  <div :class="$style.playbackButtons">
    <transition name="fade">
      <timeline-playback-button-content-intro
        v-if="isTimelinePlaybackIntroButtonsShown"
        :on-skip-intro-button-click="onSkipIntroButtonClick"
      />
    </transition>
    <transition name="fade">
      <timeline-playback-button-linked-kinom
        v-if="isLinkedKinomShown"
        :moment="linkedKinom"
        @closed="onLinkedKinomClosed"
      />
    </transition>
    <transition name="fade">
      <timeline-playback-button-next-content
        v-if="isNextEpisodeContentShown"
        :class="$style.nextContent"
        :preview-src="episodePreviewSrc as string"
        :on-request-episode="onNextEpisodeClick"
        :on-watch-titles="onWatchTitles"
        :episode-title="title"
        :is-button-animated="isButtonAnimated"
      />
    </transition>
    <transition name="fade">
      <timeline-free-episode-next-content
        v-if="isWeb && nextAfterFreeEpisode"
        ref="nextAfterFreeEpisodeEl"
        :episode="nextAfterFreeEpisode"
        :class="{
          [$style.nextEpisodeFree]: true,
          [$style.nextEpisodeFreeShown]: isTimestampActive || isNextEpisodeElementHovered,
        }"
        @mouseover="() => layoutStore.setIsNextEpisodeElementHovered(true)"
        @mouseout="() => layoutStore.setIsNextEpisodeElementHovered(false)"
      />
    </transition>
    <transition name="fade">
      <smart-tv-next-episode-preview-element
        v-if="isSmartTV && nextAfterFreeEpisode && seasonForNextAfterFreeEpisode"
        :class="{
          [$style.nextEpisodeFreeSmartTv]: true,
          [$style.nextEpisodeFreeShown]: isTimestampActive || isNextEpisodeElementHovered,
        }"
        :season="seasonForNextAfterFreeEpisode"
        :episode="nextAfterFreeEpisode"
        :is-episode-selected="false"
        :is-episode-unavailable="true"
        :durationLeftInMinutes="durationLeftInMinutes"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { isMobile } from '@PLAYER/player/base/dom';
import type { AnyFunction } from '@PLAYER/player/base/function';
import { roundNumber } from '@PLAYER/player/base/number';
import SmartTvNextEpisodePreviewElement from '@PLAYER/player/components/media-information//SmartTvNextEpisodePreviewElement.vue';
import TimelineFreeEpisodeNextContent from '@PLAYER/player/components/timeline/TimelineFreeEpisodeNextContent.vue';
import TimelinePlaybackButtonContentIntro from '@PLAYER/player/components/timeline/TimelinePlaybackButtonContentIntro.vue';
import TimelinePlaybackButtonLinkedKinom from '@PLAYER/player/components/timeline/TimelinePlaybackButtonLinkedKinom.vue';
import TimelinePlaybackButtonNextContent from '@PLAYER/player/components/timeline/TimelinePlaybackButtonNextContent.vue';
import useContentLinkedKinom from '@PLAYER/player/modules/content/use-content-linked-kinom';
import useFreeContentInformation from '@PLAYER/player/modules/content/use-free-content-information';
import useMediaTimestamps from '@PLAYER/player/modules/content/use-media-timestamps';
import useNextSerialEpisode from '@PLAYER/player/modules/content/use-next-serial-episode';
import { TimestampEvent, VideoPlayerInternalEvent } from '@PLAYER/player/modules/event/internal-event';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import useSafeVideoEventHandler from '@PLAYER/player/modules/video/use-safe-video-event-handler';
import useVideoInteractions from '@PLAYER/player/modules/video/use-video-interactions';
import { useMouseInElement } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';

const videoInteractions = useVideoInteractions();
const eventBus = useSafeEventBus();
const layoutStore = useLayoutStore();
const { isLinkedKinomShown, isNextEpisodeElementHovered } = storeToRefs(layoutStore);
const { hasLinkedKinom, linkedKinom } = useContentLinkedKinom();
const { timestamps } = useMediaTimestamps();
const { nextAfterFreeEpisode, seasonForNextAfterFreeEpisode } = useFreeContentInformation();
const { isTimestampActive } = useNextSerialEpisode();
const videoEventHandler = useSafeVideoEventHandler();
const { isWeb, isSmartTV } = usePlatform();

const { isNextEpisodeContentShown, isUserForcedWatchTitles, title, onNextEpisodeClick, episodePreviewSrc } =
  useNextSerialEpisode();

const isTimelinePlaybackIntroButtonsShown = ref(false);
const isButtonAnimated = ref(false);

const durationLeftInMinutes = computed(() =>
  roundNumber(nextAfterFreeEpisode.value ? nextAfterFreeEpisode.value?.duration / 60 : 0),
);

const nextAfterFreeEpisodeEl = ref<HTMLElement>();

const { isOutside } = useMouseInElement(nextAfterFreeEpisodeEl, { handleOutside: false });

const useSafeIntroEvent = (event: VideoPlayerInternalEvent<TimestampEvent>, callback: AnyFunction) => {
  if (event.data.type === 'media_intro') {
    return Reflect.apply(callback, undefined, []);
  }
};

const onReachedHandler = eventBus.on('onTimestampReached', (event) => {
  useSafeIntroEvent(event, () => {
    isTimelinePlaybackIntroButtonsShown.value = true;
  });
});

const onPassedHandler = eventBus.on('onTimestampPassed', (event) => {
  useSafeIntroEvent(event, () => {
    isTimelinePlaybackIntroButtonsShown.value = false;
  });
});

const onSkipIntroButtonClick = () => {
  const introTimestamp = timestamps.value.find((timestamp) => timestamp.type === 'media_intro');

  if (!introTimestamp) {
    throw new UnexpectedComponentStateError('introTimestamp');
  }

  const { end } = introTimestamp;

  if (!end) {
    throw new UnexpectedComponentStateError('introTimestamp.end');
  }

  isTimelinePlaybackIntroButtonsShown.value = false;
  videoInteractions.changeCurrentTime({
    seconds: end,
    manually: false,
  });
};

const onWatchTitles = () => {
  isUserForcedWatchTitles.value = true;
};

const onLinkedKinomClosed = () => {
  layoutStore.setIsLinkedKinomShown(false);
};

let linkedKinomTimeoutId: number;

const initializedLinkedKinom = () => {
  layoutStore.setIsLinkedKinomShown(true);

  linkedKinomTimeoutId = window.setTimeout(() => {
    layoutStore.setIsLinkedKinomShown(false);
  }, ConstantsConfigPlayer.getProperty('linkedKinomShowTimeoutMs'));
};

let nextEpisodeTimeoutId: number;

const onPlaying = () => {
  if (hasLinkedKinom.value) {
    initializedLinkedKinom();
  }
};

const onPlay = () => {
  if (nextEpisodeTimeoutId && isNextEpisodeContentShown.value) {
    window.clearTimeout(nextEpisodeTimeoutId);

    if (nextEpisodeTimeoutId) {
      window.clearTimeout(nextEpisodeTimeoutId);
    }

    nextEpisodeTimeoutId = window.setTimeout(
      onNextEpisodeClick,
      ConstantsConfigPlayer.getProperty('secondsToTheNextEpisode'),
    );
    isButtonAnimated.value = true;
  }
};

const onPause = () => {
  window.clearTimeout(nextEpisodeTimeoutId);
  isButtonAnimated.value = false;
};

if (isWeb) {
  watch(isNextEpisodeContentShown, () => {
    if (nextEpisodeTimeoutId) {
      return window.clearTimeout(nextEpisodeTimeoutId);
    }

    if (isNextEpisodeContentShown.value) {
      nextEpisodeTimeoutId = window.setTimeout(
        onNextEpisodeClick,
        ConstantsConfigPlayer.getProperty('secondsToTheNextEpisode'),
      );
      isButtonAnimated.value = true;
      return;
    }

    isButtonAnimated.value = false;
  });
}

watch(isOutside, (val) => {
  if (!isMobile || val) {
    return;
  }

  layoutStore.setIsNextEpisodeElementHovered(true);
});

onMounted(() => {
  videoEventHandler.addOnceEventListener('playing', onPlaying);
  videoEventHandler.addEventListener('play', onPlay);
  videoEventHandler.addEventListener('pause', onPause);
});

onBeforeUnmount(() => {
  if (linkedKinomTimeoutId) {
    window.clearTimeout(linkedKinomTimeoutId);
  }

  onReachedHandler.dispose();
  onPassedHandler.dispose();

  videoEventHandler.removeEventListener('playing', onPlaying);
  videoEventHandler.removeEventListener('play', onPlay);
  videoEventHandler.removeEventListener('pause', onPause);
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.playbackButtons {
  position: absolute;
  bottom: var(--timeline-playback-buttons-bottom-pos);
  left: var(--g-spacing-24);
  right: var(--g-spacing-16);
  display: flex;
  align-items: flex-end;
  width: calc(var(--root-player-width) - var(--g-spacing-40));
  justify-content: space-between;

  pointer-events: none;
}

.nextContent {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: var(--g-spacing-20);
}

.nextEpisodeFree {
  position: absolute;
  bottom: -32px;
  right: 0;
  z-index: var(--z-index-popup-view);
  display: none;
  flex-direction: column;
  justify-content: center;
  max-width: 250px;
  pointer-events: all;
}

.nextEpisodeFreeSmartTv {
  position: absolute;
  bottom: adjust.adjustPx(32px);
  right: adjust.adjustPx(48px);
  z-index: var(--z-index-popup-view);
  display: none;
  flex-direction: column;
  justify-content: center;
  pointer-events: all;
  border: adjust.adjustPx(8px) solid var(--color-notheme-bg-accent);
  outline: none;
}

.nextEpisodeFreeShown {
  display: flex;
}

@container rootVideo (max-width: 1024px) {
  .nextEpisodeFree {
    bottom: -4px;
  }
}

@container rootVideo (max-width: 480px) {
  .nextEpisodeFree {
    right: 0;
  }
}

@media (pointer: coarse) {
  .nextEpisodeFree:focus,
  .nextEpisodeFree:hover {
    display: flex;
  }
}
</style>
