<template>
  <button
    ref="buttonEl"
    :class="{
      [$style.appPlayerButton]: true,
      [$style.appPlayerWeb]: isWeb,
      [$style.appPlayerButtonSelected]: isSelected,
      [$style.appPlayerButtonSmartTv]: isSmartTV,
    }"
    tabindex="0"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts" setup>
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { AnyFunction } from '@PLAYER/player/base/function';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useKeyboardShortcuts from '@PLAYER/player/modules/keyboard/use-keyboard-shortcuts';
import { onBeforeUnmount, onMounted, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    onClick: AnyFunction;
    isSelected?: boolean;
  }>(),
  {
    onClick: () => () => ({}),
  },
);

const buttonEl = ref<HTMLButtonElement>();

const { onSmartTvKeydown } = useKeyboardShortcuts();
const { isSmartTV, isWeb } = usePlatform();

const onKeydown = onSmartTvKeydown(props.onClick);

onMounted(() => {
  if (!buttonEl.value) {
    throw new UnexpectedComponentStateError('buttonEl');
  }

  buttonEl.value.addEventListener('click', props.onClick);
  buttonEl.value.addEventListener('keydown', onKeydown);
});

onBeforeUnmount(() => {
  if (!buttonEl.value) {
    throw new UnexpectedComponentStateError('buttonEl');
  }

  buttonEl.value.removeEventListener('click', props.onClick);
  buttonEl.value.removeEventListener('keydown', onKeydown);
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as webFonts;
@use '@package/ui/src/styles/smarttv-fonts.scss' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.appPlayerButton {
  margin: 0;
  padding: 0;
  border-radius: adjust.adjustPx(16px);
  background-color: var(--color-notheme-bg-secondary-80);
  font-family: inherit;
  outline: 0;
  color: var(--color-notheme-text-primary);
  border-width: 0;
  white-space: nowrap;
  text-align: center;
  transition:
    background-color 0.3s ease-in-out,
    color 0.4s ease-in-out,
    border-color 0.3s ease-in-out;
  pointer-events: all;

  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    background-color: var(--color-notheme-bg-hover-70);
    cursor: pointer;
  }
}
.appPlayerWeb {
  backdrop-filter: blur(var(--player-default-backdrop-filter-modal-blur));
  @include webFonts.WebLabel-3();
}

.appPlayerButtonSelected {
  background-color: var(--color-notheme-bg-hover-70);
}

.appPlayerButtonSmartTv {
  border: adjust.adjustPx(8px) solid transparent;

  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    border: adjust.adjustPx(8px) solid var(--color-notheme-bg-accent);
  }

  @include smartTvFonts.SmartTvBody-3();
}
</style>
