<template>
  <div
    :class="{
      [$style.label]: true,
      [$style.labelWeb]: isWeb,
      [$style.labelSmartTv]: isSmartTV,
    }"
    :style="{
      position: position,
      left: left ? $toPixel(left) : undefined,
      top: top ? $toPixel(top) : undefined,
    }"
  >
    <span>
      {{ text }}
    </span>
    <slot name="icon"></slot>
  </div>
</template>

<script lang="ts" setup>
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';

withDefaults(
  defineProps<{
    position?: 'absolute' | 'fixed' | 'relative';
    text: string;
    left?: number;
    top?: number;
  }>(),
  {
    position: 'fixed',
  },
);

const { isSmartTV, isWeb } = usePlatform();
</script>

<style module lang="scss">
@use '@package/ui/src/styles/fonts.scss' as webFonts;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.label {
  z-index: var(--z-index-label-tips);
  padding: var(--g-spacing-2, 2px) var(--g-spacing-6) var(--g-spacing-4) var(--g-spacing-6);
  border-radius: var(--g-border-round-8);
  background-color: var(--color-notheme-bg-secondary-80);
  color: var(--color-notheme-text-secondary);
  white-space: nowrap;

  /*
    Safari tries to select text inside volume label
    when session is changing volume
  */
  user-select: none;

  /*
    pointer-events: none; is used to fix chrome-related issue
    https://github.com/facebook/react/issues/4492
  */
  pointer-events: none;
  @include webFonts.WebCaption-1();
}

.labelWeb {
  backdrop-filter: blur(var(--player-default-backdrop-filter-modal-blur));
}

.labelSmartTv {
  transition: initial;
  @include smartTvFonts.SmartTvBody-3();
}
</style>
