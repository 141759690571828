<template>
  <div
    :class="{
      [$style.videoPlayer]: true,
      [$style.videoPlayerLive]: isLive,
    }"
  >
    <div :class="$style.videoPlayerWrapper">
      <tizen-object-tag-element
        v-if="mediaSourceTechPlaybackType === 'avplayer'"
        :ref="(comp) => setVideoElement(comp?.$el)"
      />
      <video-tag-element
        v-else
        :ref="(comp) => setVideoElement(comp?.$el)"
        :is-scaled="false"
        :use-poster="false"
        :poster-src="normalizedPosterSrc"
        :muted="normalizedMuted"
        :loop="normalizedLoop"
        :autoplay="isAutoplay"
        :preload="preload"
      />
      <transition name="fade">
        <background-gradients v-if="isGradientsShown" />
      </transition>
      <transition name="fade">
        <app-context-menu v-if="isContextMenuShown" />
      </transition>
      <initial-volume-setter />
      <!-- loader -->
      <transition name="fade">
        <smart-tv-main-loader v-if="isSmartTvLoaderShown" :type="loaderType" />
      </transition>
      <!-- debug -->
      <transition name="fade">
        <debug-information-view v-if="isDebugVisible" />
      </transition>
      <transition name="fade">
        <popup-abstract-layer v-if="isShownPopup" />
      </transition>
      <!-- critical-error -->
      <transition name="fade">
        <critical-error-view
          v-if="hasCriticalError"
          :title="errorTitle"
          :text="errorText"
          :show-reload-page-notification="restorePlayerRestoreCountExceeded"
        />
      </transition>
      <current-video-action-view v-if="isCurrentActionViewShown" />
      <!-- autoplay-detector -->
      <auto-play-detector ref="autoplayDetector" :is-allow-autoplay="isAutoplay" />
      <!-- media-user-watching-progress -->
      <media-user-watching-progress-observer />
      <!-- timestamps-detector -->
      <timestamps-detector-view />
      <!-- Короче, внутри этого компонента нам доступен видео элемент (useSafeVideoElement) -->
      <video-element-manager-layer>
        <smart-tv-keyboard-commands-retainer />
        <smart-tv-video-controls-timeline-layer :loop="normalizedLoop" />
      </video-element-manager-layer>
      <slot />
      <!-- Отображение зацензуренного контента -->
      <adult-content-warning-setter v-if="isVOD" :key="manifestUrl" />
      <!-- hls -->
      <media-source-initializer v-if="isPlayerOnboardingPassed" :key="currentHlsInitializerViewKey" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import ConstantsConfig from '@package/constants/code/constants-config-player';
import useLocalStorage from '@package/sdk/src/core/dom/use-local-storage';
import { LocalStorageKey } from '@PLAYER/player/base/local-storage';
import MediaSourceInitializer from '@PLAYER/player/components/app-video-context/MediaSourceInitializer.vue';
import DebugInformationView from '@PLAYER/player/components/debug/DebugInformationLayer.vue';
import CriticalErrorView from '@PLAYER/player/components/error/CriticalErrorView.vue';
import AdultContentWarningSetter from '@PLAYER/player/components/media-information/AdultContentWarningSetter.vue';
import MediaUserWatchingProgressObserver from '@PLAYER/player/components/media-information/MediaUserWatchingProgressObserver.vue';
import TizenObjectTagElement from '@PLAYER/player/components/player/TizenObjectTagElement.vue';
import VideoElementManagerLayer from '@PLAYER/player/components/player/VideoElementManagerLayer.vue';
import VideoTagElement from '@PLAYER/player/components/player/VideoTagElement.vue';
import PopupAbstractLayer from '@PLAYER/player/components/popups/PopupAbstractLayer.vue';
import BackgroundGradients from '@PLAYER/player/components/ui/BackgroundGradients.vue';
import AppContextMenu from '@PLAYER/player/components/video/AppContextMenu.vue';
import AutoPlayDetector from '@PLAYER/player/components/video/AutoplayDetector.vue';
import CurrentVideoActionView from '@PLAYER/player/components/video/CurrentVideoActionView.vue';
import InitialVolumeSetter from '@PLAYER/player/components/video/InitialVolumeSetter.vue';
import TimestampsDetectorView from '@PLAYER/player/components/viewport/TimestampsDetectorView.vue';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import type { PlayerProjector } from '@PLAYER/player/modules/instance/interfaces';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import useManifestStore from '@PLAYER/player/modules/store/manifest-store';
import provideGlobalVideoElements from '@PLAYER/player/modules/video/provide-global-video-elements';
import useGlobalErrorHandler from '@PLAYER/player/modules/video/use-global-error-handler';
import useVideoControlsVariables from '@PLAYER/player/modules/video/use-video-controls-variables';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import SmartTvKeyboardCommandsRetainer from '@PLAYER/player/versions/smart/components/controls/SmartTvKeyboardCommandsRetainer.vue';
import SmartTvVideoControlsTimelineLayer from '@PLAYER/player/versions/smart/components/controls/SmartTvVideoControlsTimelineLayer.vue';
import SmartTvMainLoader from '@PLAYER/player/versions/smart/components/video/SmartTvMainLoader.vue';
import { isClient } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    projector: PlayerProjector;
    src?: string;
    preload?: boolean;
    posterSrc?: string;
    autoplay?: boolean;
    loop?: boolean;
    muted?: boolean;
  }>(),
  { posterSrc: '' },
);

const layoutStore = useLayoutStore();
const { currentPopupComponentName, isNextContentEpisodeShown } = storeToRefs(layoutStore);
const localStorage = useLocalStorage();
const eventBus = useSafeEventBus();
const { isKinom, isVOD, isLive } = useProjector();
const { mediaSourceTechPlaybackType } = useVideoPlayerVariables();
const { isControlsShown } = useVideoControlsVariables();

const { manifestUrl, currentHlsInitializerViewKey } = storeToRefs(useManifestStore());

const shouldSmartTvOnboardingUseless = (() => {
  if (!ConstantsConfig.getProperty('isOnboardingEnabled')) {
    return true;
  }

  if (!isClient) {
    return false;
  }

  if (isKinom.value) {
    return true;
  }

  return localStorage.getValue(LocalStorageKey.SmartTvOnboardingControl, false)?.value;
})();

const isPlayerOnboardingPassed = ref(shouldSmartTvOnboardingUseless);

eventBus.on('onOnboardingPassed', () => {
  isPlayerOnboardingPassed.value = true;
});

onBeforeMount(() => {
  if (isPlayerOnboardingPassed.value) {
    return;
  }

  layoutStore.setPopup('SmartTvOnboardingPopupControls');
});

const { hasCriticalError, errorText, errorTitle, restorePlayerRestoreCountExceeded } = useGlobalErrorHandler();

const {
  normalizedPosterSrc,
  normalizedLoop,
  normalizedMuted,
  isContextMenuShown,
  isCurrentActionViewShown,
  isLoaderShown,
  loaderType,
  isAutoplay,
} = useVideoPlayerVariables({
  loop: props.loop,
  posterSrc: props.posterSrc,
  muted: props.muted,
  autoplay: props.autoplay,
});

const isSmartTvLoaderShown = computed(() => {
  if (isKinom.value) {
    return false;
  }

  return isLoaderShown.value;
});

const isGradientsShown = computed(() => {
  if (isNextContentEpisodeShown.value) {
    return true;
  }

  if (isVOD.value || isLive.value) {
    // Если открыты контролы
    if (isControlsShown.value) {
      return true;
    }

    // Если показан попап
    return Boolean(currentPopupComponentName.value);
  }

  return !isLoaderShown.value;
});

const { isDebugVisible, isShownPopup } = storeToRefs(layoutStore);

const { setVideoElement } = provideGlobalVideoElements({ initialSrc: props.src });
</script>

<style lang="scss" module>
.videoPlayerWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.videoPlayer {
  width: 100%;
  height: 100%;

  --vod-playback-time-view-width: 60px;
}

.videoPlayerLive {
  --vod-playback-time-view-width: 0px;
}
</style>
